.slider {
  overflow: hidden;
  position: relative;
 /* DOTS */
 /* ARROWS */
}
.slider .slide-list {
  display: flex;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}
.slider .slide-list .slide {
  flex: 1 0 100%;
  position: relative;
}
.slider .slide-list .slide .slide-image {
  display: flex;
  margin: 0 auto;
  max-height: 400px;
  width: 100%;
  object-fit: contain;
}
.slider .slide-list .slide .slide-title {
  text-align: center;
  margin-top: 10px;
}
.slider .dots {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  position: relative;
  z-index: 2;
}
.slider .dots .dot {
  background-color: #e7e7e7;
  border-radius: 5px;
  height: 10px;
  margin: 5px;
  width: 10px;
}
.slider .dots .dot:hover {
  cursor: pointer;
}
.slider .dots .dot.selected {
  background-color: #1e4f9b;
}
.slider .arrows {
  color: white;
  display: flex;
  font-size: 30px;
  justify-content: center;
  height: 100%;
  margin-top: 20px;
  width: 100%;
  gap: 30px;
}
.slider .arrows .arrow {
  height: 25px;
  width: 25px;
  border: 5px solid #1e4f9b;
  border-right: 0;
  border-bottom: 0;
  transition: all 0.2s ease-in-out;
}
.slider .arrows .arrow:hover {
  cursor: pointer;
  border-color:#3073ff;
}
.slider .arrows .arrow.left {
  /* background-image: url(../../assets/icons/ic-arrow.png); */
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
  transform: rotate(-45deg);
}
.slider .arrows .arrow.right {
  /* background-image: url(../../assets/icons/ic-arrow.png); */
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5px;
  transform: rotate(135deg);
}
